import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import Title from "./Title";
// img
import imgDetail from "../assets/IMG_3411.jpg";
import imgDetail2 from "../assets/IMG_3413.jpg";
import imgDetail3 from "../assets/IMG_3424.jpg";

const GetStarted = () => {
  const CustomGridItem = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center", // This will center the content horizontally in the container, which is what you want for vertical centering.
    [theme.breakpoints.up("md")]: {
      "&:nth-of-type(odd)": {
        flexDirection: "row-reverse",
      },
    },
  }));

  const CustomTypography = styled(Typography)({
    fontSize: "1.1rem",
    textAlign: "start",
    lineHeight: "1.5",
    color: "#515151",
    marginTop: "1.5rem",
  });

  return (
    <Grid
      container
      spacing={{ xs: 4, sm: 4, md: 0 }}
      sx={{
        py: 10,
        px: 2,
      }}
    >
      {/* First row: Image on the left, text on the right */}
      <Grid item xs={12} sm={4} md={6}>
        <img
          src={imgDetail}
          alt="Outdoor scene"
          style={{
            width: "100%",
            display: "block",
          }}
        />
      </Grid>
      <CustomGridItem item xs={12} sm={8} md={6}>
        <Box component="article" sx={{ px: 4 }}>
          <Title text={"Our Commitment"} textAlign={"start"} />
          <CustomTypography>
            Since many years, we have been dedicated to creating sustainable,
            beautiful, and functional outdoor environments that enhance the
            quality of life for residents and visitors alike. From intimate
            garden retreats to expansive public parks, we approach every project
            with creativity, expertise, and a keen eye for detail.
          </CustomTypography>
        </Box>
      </CustomGridItem>

      {/* Second row: Text on the left, image on the right */}
      <CustomGridItem
        item
        xs={12}
        sm={8}
        md={6}
        sx={{ order: { xs: 2, md: 0 } }}
      >
        <Box component="article" sx={{ px: 4 }}>
          <Title text={"Our Expertise"} textAlign={"start"} />
          <CustomTypography>
            Led by a team of experienced horticulturists, landscape designers,
            and gardening professionals, Brahma Farms & Nursery brings a wealth
            of knowledge and skills to every project. With a focus on native
            plants, eco-friendly practices, and innovative design solutions, we
            are committed to delivering exceptional results that exceed our
            clients' expectations.
          </CustomTypography>
        </Box>
      </CustomGridItem>
      <Grid item xs={12} sm={4} md={6} sx={{ order: { xs: 1, md: 0 } }}>
        <img
          src={imgDetail2}
          alt="Indoor bookshelf scene"
          style={{
            width: "100%",
            display: "block",
          }}
        />
      </Grid>

      {/* Third row: Image on the left, text on the right */}
      <Grid item xs={12} sm={4} md={6}>
        <img
          src={imgDetail3}
          alt="Outdoor scene"
          style={{
            width: "100%",
            display: "block",
          }}
        />
      </Grid>
      <CustomGridItem item xs={12} sm={8} md={6}>
        <Box component="article" sx={{ px: 4 }}>
          <Title text={"Our Philosophy"} textAlign={"start"} />
          <CustomTypography>
            At Brahma Farms & Nursery, we believe that great landscapes are more
            than just beautiful—they are living, breathing spaces that nurture
            the human spirit and support ecological diversity. We strive to
            create environments that not only look stunning but also promote
            health, happiness, and sustainability for generations to come.
          </CustomTypography>
        </Box>
      </CustomGridItem>
    </Grid>
  );
};

export default GetStarted;
