import React, { useState } from "react";
// mui
import { Typography, Box, Stack } from "@mui/material";
// carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// components
import Title from "./Title";
import Paragraph from "./Paragraph";

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState();

  const imageData = [
    {
      alt: "image1",
      url: "https://images.pexels.com/photos/259751/pexels-photo-259751.jpeg?cs=srgb&dl=pexels-pixabay-259751.jpg&fm=jpg",
    },
    {
      alt: "image2",
      url: "https://images.pexels.com/photos/5411784/pexels-photo-5411784.jpeg?cs=srgb&dl=pexels-andrea-davis-5411784.jpg&fm=jpg",
    },
    {
      alt: "image3",
      url: "https://images.pexels.com/photos/356809/pexels-photo-356809.jpeg?cs=srgb&dl=pexels-daniel-frank-356809.jpg&fm=jpg",
    },
    {
      alt: "image4",
      url: "https://images.pexels.com/photos/6267516/pexels-photo-6267516.jpeg?cs=srgb&dl=pexels-get-lost-mike-6267516.jpg&fm=jpg",
    },
    {
      alt: "image5",
      url: "https://images.pexels.com/photos/667838/pexels-photo-667838.jpeg?cs=srgb&dl=pexels-huseyn-kamaladdin-667838.jpg&fm=jpg",
    },
  ];

  const renderSlides = imageData.map((image) => (
    <div key={image.alt}>
      <img src={image.url} alt={image.alt} />
    </div>
  ));

  const handleChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        py: 8,
        px: 2,
        display: { xs: "flex" },
      }}
    >
      <Box
        component="section"
        sx={{
          paddingBottom: 3,
        }}
      >
        <Title
          text={"Welcome to Brahma Farms & Nursery"}
          textAlign={"center"}
        />
        <Paragraph
          text={
            "At Brahma Farms & Nursery, we are passionate about creating vibrant and sustainable outdoor spaces that enhance communities and enrich lives. With a deep-rooted commitment to excellence in horticulture, landscape design, and maintenance, we have been transforming parks, gardens, and public spaces."
          }
          maxWidth={"sm"}
          mx={"auto"}
          textAlign={"center"}
        />
      </Box>
      <Box
        component="section"
        sx={{
          paddingBottom: 3,
        }}
      >
        <Title text={"Our Mission"} textAlign={"center"} />
        <Paragraph
          text={
            "Our mission is to design, develop, and maintain outdoor environments that inspire and delight. We believe in the power of green spaces to foster connection, promote well-being, and celebrate the beauty of nature."
          }
          maxWidth={"sm"}
          mx={"auto"}
          textAlign={"center"}
        />
      </Box>
      <Box
        component="section"
        sx={{
          paddingBottom: 3,
        }}
      >
        <Title text={"Our Team"} textAlign={"center"} />
        <Paragraph
          text={
            "Led by a team of horticulture experts and landscape designers, Brahma Farms & Nursery brings together a diverse group of professionals dedicated to delivering exceptional results. "
          }
          maxWidth={"sm"}
          mx={"auto"}
          textAlign={"center"}
        />
      </Box>
      <Box
        component="section"
        sx={{
          paddingBottom: 3,
        }}
      >
        <Title text={"Our Approach"} textAlign={"center"} />
        <Paragraph
          text={
            "At Brahma Farms & Nursery, we approach every project with a blend of creativity, innovation, and environmental stewardship. We work closely with clients to understand their vision, preferences, and budget, ensuring that every design reflects their unique needs and aspirations. From initial concept development to final and ongoing maintenance, we prioritize quality, sustainability, and customer satisfaction every step of the way."
          }
          maxWidth={"sm"}
          mx={"auto"}
          textAlign={"center"}
        />
      </Box>
    </Stack>
  );
};

export default Gallery;
