import React from "react";
import { Box, Button, Stack, TextField } from "@mui/material";
import Title from "./Title";
import Paragraph from "./Paragraph";

const Details = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      phone: data.get("phone"),
    });
  };

  return (
    <Stack
      component="section"
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        py: 10,
        px: 2,
      }}
    >
      <Title text={"Contact Us"} textAlign={"center"} />
      <Paragraph
        text={
          "We would be more than happy to have a word with you for our services or feedback."
        }
        maxWidth={"sm"}
        mx={0}
        textAlign={"center"}
      />

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{
          mt: 1,
          py: 2,
        }}
      >
        Brahma Farms & Nursery <br />
        C-4/11, YAMUNA VIHAR,
        <br />
        North East Delhi,
        <br />
        Delhi, 110053. <br /> brahmafarms@gmail.com <br />
        9899227570
      </Box>
    </Stack>
  );
};

export default Details;
