import React from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  Paper,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download"; // Import the download icon
import Heading from "../components/Heading";
import FileDownloadModal from "../components/FileDownloadModal";

//files downloadable
import form_v from "../assets/BrahmaFarms.pdf";
import pf_challan from "../assets/PF CHALLAN BRAHMA FARM & NURSERY MARCH -- 2024.pdf";
import bank_sheet from "../assets/Bank Sheet.pdf";
import esic_challan from "../assets/ESI ECR BRAHMA FARM & NURSERY MARCH -- 2024.pdf";
import salary_sheet from "../assets/SALARY SHEET.pdf";

const ComplianceBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10, 2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(10),
  },
}));

const ComplianceTitle = styled(Typography)({
  fontWeight: 700,
  paddingBottom: "1rem",
});

const StyledTableCell = styled(TableCell)({
  borderBottom: "none",
});

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9", // For zebra striping, change as needed
  },
});

const ButtonGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

const LabourCompliance = () => {
  // Define your button data
  const buttons = [
    "PF Challan",
    "ESIC Challan",
    "Salary Sheet",
    "Bank Transfer Sheet",
    "Wages Sheet",
    "Attendance",
  ];

  const table1 = [
    [
      "Name and Address of the Contractor’s establishment",
      "Brahma Farms & Nursery C-4/11, YAMUNA VIHAR, North East Delhi, Delhi, 110053.",
    ],
    [
      "Website address",
      <a
        href="https://brahmafarms.in/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://brahmafarms.in/
      </a>,
    ],
    [
      "Name and address of the Proprietor/ Director/ Partners",
      "Sunita Sharma C-4/11, YAMUNA VIHAR, North East Delhi, Delhi, 110053.",
    ],
    [
      "Phone/ Mobile Number and E-mail I.D. of establishment/ person in-charge of contract work",
      "9899227570 brahmafarms@gmail.com",
    ],
    [
      "Name and address of the place/ site where the contract workers are to be employed",
      "Sunder Nursery, Nizamuddin, New Delhi-110013",
    ],
    // ... Add all other data rows similarly
    [
      "Form “ V” issued by P.E. – Rule 21 (2)",
      <Button variant="outlined" onClick={() => downloadHandler(form_v)}>
        Download
      </Button>,
    ],
    [
      "Agreement with P.E.",
      <Button
        variant="outlined"
        onClick={() => {
          // downloadHandler("agreement_pe")
        }}
      >
        Download
      </Button>,
    ],
    [
      "Letter of extension of contract period, in case of renewal of license",
      "N/A",
    ],
    ["Wages are being disbursed through account payee cheque/ ECS.", "Yes"],
    ["License No. under CLRA Act, 1970", "A/F"],
    [
      "Nature of Work for which contract workers are to be engaged/employed",
      "Horticulture Work- Garden Feature Maintenance",
    ],
    [
      "Registration Number/ Certificate under the Delhi Shops & Establishment Act, 1954",
      "2023041792",
    ],

    ["GST Number", "07BESPS3150Q1ZM"],
    ["PF Code Number", "DSSHD2535730000"],
    ["ESI Code Number", "10001322590000999"],
  ];

  const table2 = [
    // Principal Employer Details
    [
      "Name and address of the Principal Employer",
      "Govt. Sunder Nursery Management Trust, Nizamuddin, New Delhi 110013",
    ],
    [
      "Website address",
      <a
        href="https://www.sundernursery.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.sundernursery.org
      </a>,
    ],
    [
      "Name/Designation, Address and Phone number of person in-charge of contract work/ site",
      "Hardeep Thakur, Manager Operations Govt. Sunder Nursery Management Trust, Nizamuddin, New Delhi 110013 Ph: 011-40700700",
    ],
    [
      "Registration Certificate No. under CLRA Act, 1970",
      <Button variant="outlined" onClick={() => downloadHandler(form_v)}>
        Download
      </Button>,
    ],
  ];

  const downloadHandler = (filename) => {
    const link = document.createElement("a");
    link.href = filename;
    link.download = filename.split("/").pop(); // This will extract the filename and use it as the download name.
    document.body.appendChild(link); // Append to page
    link.click(); // Programmatically click to trigger the download
    document.body.removeChild(link); // Clean up and remove the link
  };

  return (
    <ComplianceBox>
      <ComplianceTitle variant="h4">Labour Compliance</ComplianceTitle>

      {/* <ButtonGroup>
        {buttons.map((buttonText, index) => (
          <Button
            key={index}
            variant="contained"
            onClick={() => console.log(`${buttonText} clicked`)}
          >
            {buttonText}
          </Button>
        ))}
      </ButtonGroup> */}
      <ButtonGroup>
        <FileDownloadModal fileName="PF Challan" fileUrl={pf_challan} />
        <FileDownloadModal fileName="ESIC Challan" fileUrl={esic_challan} />
        <FileDownloadModal fileName="Salary Sheet" fileUrl={salary_sheet} />
        <FileDownloadModal
          fileName="Bank Transfer Sheet"
          fileUrl={bank_sheet}
        />
        <FileDownloadModal fileName="Wages Sheet" fileUrl={salary_sheet} />
        <FileDownloadModal fileName="Attendance" fileUrl={salary_sheet} />
      </ButtonGroup>

      <Paper elevation={3}>
        <Box component="article" sx={{ px: 4 }}>
          <Heading text={"Contractor Details:"} textAlign={"start"} />
          <Table>
            <TableBody>
              {/* Map your data to table rows here */}
              {table1.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row[0]}
                  </StyledTableCell>
                  <StyledTableCell>{row[1]}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Box component="article" sx={{ px: 4 }}>
          <Heading text={"Principal Employer Details:"} textAlign={"start"} />
          <Table>
            <TableBody>
              {/* Map your data to table rows here */}
              {table2.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row[0]}
                  </StyledTableCell>
                  <StyledTableCell>{row[1]}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    </ComplianceBox>
  );
};

export default LabourCompliance;
