import React from "react";
import { Box, Typography, Button, styled } from "@mui/material";
import { Link } from "react-router-dom";

const ClientsBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#f5f5f5", // Light gray background, change as per your color scheme
  padding: theme.spacing(10, 2), // Vertical padding of 10 and Horizontal padding of 2
  display: "flex",
  flexDirection: "column",
  alignItems: "start", // Align items to the start of the box
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(10), // Increase padding on larger screens
  },
}));

const ClientsTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700, // Bold text
  paddingBottom: theme.spacing(2), // Space below the title
}));

const ClientsText = styled(Typography)({
  paddingBottom: "1rem", // Space below each paragraph
});

const ClientsButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2), // Margin top for the button
  textTransform: "none", // Prevent uppercase transformation
  // Add more styling here for the button as per your design requirements
}));

const Clients = () => {
  return (
    <ClientsBox>
      <ClientsTitle variant="h4" gutterBottom>
        Our Clients
      </ClientsTitle>
      <ClientsText variant="body1">
        At Brahma Farms & Nursery, we have had the privilege of working with a
        diverse range of clients, including:
      </ClientsText>
      <ClientsText variant="body1" component="div">
        <strong>Govt. Sunder Nursery Management Trust:</strong>
        <br />
        Appointed as Horticulture Contractor for Maintenance of the garden
        features of the Sunder Nursery under Govt. Sunder Nursery Management
        Trust.
      </ClientsText>
      <ClientsText variant="body1">
        Ready to Join Our Growing List of Satisfied Clients?
      </ClientsText>
      <ClientsButton component={Link} to="/contact" variant="contained">
        Contact us today
      </ClientsButton>
    </ClientsBox>
  );
};

export default Clients;
