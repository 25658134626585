import React, { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import salarySheetPdf from "../assets/salary_sheet.pdf"; // Import the files from the assets folder

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// Reusable Modal component for file downloads
const FileDownloadModal = ({ fileName, fileUrl }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen} variant="contained">
        {fileName}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Download File
          </Typography>
          <Box
            mt={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1">{fileName}</Typography>
            <Button variant="contained" href={fileUrl} download>
              Download
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default FileDownloadModal;
