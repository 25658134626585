import { Typography } from "@mui/material";
import React from "react";

const Heading = ({ text, textAlign }) => {
  return (
    <Typography
      variant="h5"
      component="h5"
      sx={{
        fontWeight: "700",
        textAlign: textAlign,
      }}
    >
      {text}
    </Typography>
  );
};

export default Heading;
