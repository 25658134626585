import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
//img
import headerImg from "../assets/pexels-binyamin-mellish-186078.png";

const Header = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    // Adjust the minHeight to something less than 80vh if you want to reduce the height of the orange area.
    // For example, you can change it to 50vh or even a fixed height like 500px depending on your needs.
    minHeight: "55vh",
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center", // This will ensure the content is vertically centered
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
    // You might want to adjust the padding here as well, especially if you have a fixed height.
    // If the paddingTop is too much, reduce it accordingly.
    // Also, consider adding paddingBottom if necessary to center the content vertically.
    paddingBottom: theme.spacing(4), // Add paddingBottom to balance paddingTop
    backgroundColor: "orange",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto", // On smaller screens, let the content dictate the height instead of minHeight.
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      paddingTop: theme.spacing(5), // Reduce the padding on smaller screens if needed
      paddingBottom: theme.spacing(5), // Consistent padding at the bottom for smaller screens
    },
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: "1",
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flex: "2",
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <CustomBox component="header">
      {/*  Box text  */}
      <BoxText component="section">
        <Typography
          variant="h2"
          component="h1"
          sx={{
            fontWeight: 700,
            color: "#fff",
          }}
        >
          Welcome to Brahma Farms & Nursery
        </Typography>

        <Typography
          variant="p"
          component="p"
          sx={{
            py: 3,
            lineHeight: 1.6,
            color: "#fff",
          }}
        >
          At Brahma Farms & Nursery, we believe in the transformative power of
          nature. With a deep-rooted passion for horticulture and landscape
          design, we specialize in the development and maintenance of parks,
          gardens, and outdoor spaces that inspire and enrich communities.
        </Typography>

        <Box>
          {/* <Button
            variant="contained"
            sx={{
              mr: 2,
              px: 4,
              py: 1,
              fontSize: "0.9rem",
              textTransform: "capitalize",
              borderRadius: 0,
              borderColor: "#14192d",
              color: "#fff",
              backgroundColor: "#14192d",
              "&&:hover": {
                backgroundColor: "#343a55",
              },
              "&&:focus": {
                backgroundColor: "#343a55",
              },
            }}
          >
            buy now
          </Button> */}
          <Button
            component={Link}
            to={"/about"}
            variant="outlined"
            sx={{
              px: 4,
              py: 1,
              fontSize: "0.9rem",
              textTransform: "capitalize",
              borderRadius: 0,
              color: "#fff",
              backgroundColor: "transparent",
              borderColor: "#fff",
              "&&:hover": {
                color: "#343a55",
                borderColor: "#343a55",
              },
              "&&:focus": {
                color: "#343a55",
                borderColor: "#343a55",
              },
            }}
          >
            explore
          </Button>
        </Box>
      </BoxText>

      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "flex-end", // Aligns the image to the right

          maxHeight: "600px", // Set a max-width for the text box to control the width

          [theme.breakpoints.down("md")]: {
            flex: "1",
            paddingTop: "30px",
            alignSelf: "center",
            maxWidth: "100%", // Allows the text box to be full width on smaller screens
          },
          [theme.breakpoints.up("md")]: {
            flex: "2",
            alignSelf: "flex-end",
          },
        })}
      >
        <img
          src={headerImg}
          alt="headerImg"
          style={{
            width: "50%",
            marginBottom: 115,
          }}
        />
      </Box>
    </CustomBox>
  );
};

export default Header;
